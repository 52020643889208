/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/

.banner{
    background-image: url('../backgrounds/banner-home.jpg');
    background-size: cover;
    background-position: center;
    height: 250px; /* Ajusta la altura según sea necesario */
}
.spaIcon{
    height: 47px;
    width: auto;
}

.banner .container {
color: #fff; /* Color del texto */
text-align: center;
filter: brightness(1.5);
}

.banner::before {
content: "";
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.3); /* Color de fondo oscuro con transparencia */
}

.carrouselContainer{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 600px;
    overflow-x: scroll;
    scrollbar-width: none;
}

.carrouselContainer_mobil{
    position: absolute;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 4300px;
    /* padding: 15% 0 15% 0; */
}

.carrouselContainer_mobil img{
    object-fit: cover;
    height: 100%;
}


/* Estilo personalizado para los indicadores de tipo círculo */
.carousel-indicators {
    bottom: 0px; /* Ajusta la posición vertical según sea necesario */
}

.carousel-indicators button {
    width: 10px !important; /* Ajusta el tamaño del indicador */
    height: 10px !important; /* Ajusta el tamaño del indicador */
    border-radius: 50%; /* Hace que el indicador sea circular */
    border: none; /* Elimina el borde del indicador */
    background-color: #ccc; /* Establece el color del indicador */
    margin: 0 4px; /* Ajusta el espaciado horizontal entre indicadores */
    opacity: 1; /* Ajusta la opacidad del indicador activo */
    transition: opacity 0.15s ease; /* Agrega una transición suave */
}

.carousel-indicators button.active {
    background-color: white; /* Establece el color del indicador activo */
}
.carousel-inner{
    height: 250px;
}

.carousel-item{
    width: 100%;
    height: 100%;
}

.carousel-inner img {
    object-fit: cover; /* Ajusta el tamaño de la imagen para ajustarse al contenedor */
    height: 100%; /* Asegura que la imagen no se salga del contenedor */
    max-width: 100%; /* Asegura que la imagen no se salga del contenedor */
}
.spa-gallery{
    height: 550px;
    overflow-x: scroll;
}
.spa-gallery-mobil{
    width: 200%;
    position: absolute;
    top:0;
    left: 0;
    white-space: nowrap;
    animation: scroll 10s ease-in-out alternate;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-50%);
    }

    100% {
        transform: translateX(0%);
    }
}

.homeInfoContent{
    border-bottom: 1px solid #6e6e6e31;
}

.faqAccordion .accordion-item{
    background-color: white !important;
}

.faqAccordion .accordion-button{
    background-color: white !important;
    color: #394C02 !important;
    font-size: 1rem;
}
.faqAccordion p{
    font-size: 1rem;
}

/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {
    .banner{
        height: 400px; /* Ajusta la altura según sea necesario */
    }
    .fs-lg-6{
        font-size: 1.2rem !important;
    }
    .fs-lg-2{
        font-size: 2rem !important;
    }
    .carrouselContainer_mobil{
        width: 4050px;
        /* padding: 10% 0 10% 0; */
    }

    .carousel-inner{
        min-height: 400px;
    }

    .spa-gallery{
        height: 760px;
    }
    .spa-gallery-mobil{
        width: 150%;
    }
    @keyframes scroll {
        0% {
            transform: translateX(0);
        }

        100% {
            transform: translateX(-35%);
        }
    }

    .faqAccordion .accordion-button{
        font-size: 1rem;
    }
    .faqAccordion p{
        font-size: 1rem;
    }
}

/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 800px) {
    .banner{
        height: 500px; /* Ajusta la altura según sea necesario */
    }

    .fs-lg-6{
        font-size: 1.2rem !important;
    }
    .fs-lg-2{
        font-size: 2.3rem !important;
    }
    .carrouselContainer{
        overflow: hidden;
        height: 700px;
    }
    .carrouselContainer_mobil{
        scrollbar-width: none;
        width: 4500px;
        /* padding: 5% 0 5% 0; */
        transition: all 0.5s ease-in-out;
    }
    .carrouselContainer_mobil-position-1{
        left: 0;
    }
    .carrouselContainer_mobil-position-2{
        left: -900px;
    }
    .carrouselContainer_mobil-position-3{
        left: -1800px;
    }
    .carrouselContainer_mobil-position-4{
        left: -2700px;
    }
    .carrouselContainer_mobil-position-5{
        left: -3600px;
    }

    .carrousel-navigator{
        z-index: 150;
    }
    .carrousel-navigator button{
        background-color: transparent;
        border: none;
        cursor: pointer;
    }


    .carousel-inner{
        min-height: 300px;
    }

    .spa-gallery{
        height: auto;
        overflow-x: hidden;
    }
    .spa-gallery-mobil{
        position: relative;
        width: 100%;
        animation: none;
    }

    .homeInfoContent{
        border-bottom:none;
        border-right: 1.5px solid #6e6e6e31;
    }


    .faqAccordion .accordion-button{
        font-size: 1.2rem;
    }
    .faqAccordion p{
        font-size: 1.1rem;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {
    .banner{
        height: 650px; /* Ajusta la altura según sea necesario */
    }

    .fs-lg-6{
        font-size: 1.5rem !important;
    }

    .fs-lg-2{
        font-size: 2.5rem !important;
    }
    .carrouselContainer{
        height: 800px;
    }
    .carrouselContainer_mobil{
        scrollbar-width: none;
        width: 4400px;
        /* padding: 5% 0 5% 0; */
        transition: all 0.5s ease-in-out;
    }

    .carrouselContainer_mobil-position-1{
        left: 0;
    }
    .carrouselContainer_mobil-position-2{
        left: -900px;
    }
    .carrouselContainer_mobil-position-3{
        left: -1800px;
    }
    .carrouselContainer_mobil-position-4{
        left: -2700px;
    }
    .carrouselContainer_mobil-position-5{
        left: -3800px;
    }

    .carousel-inner{
        min-height: 450px;
    }
}


/*! ########################################*/
/*! ######### EXTRA LARGER DESKTOPS ########*/
/*! ########################################*/
@media (min-width: 1600px) {
    .banner{
        height: 750px; /* Ajusta la altura según sea necesario */
    }

    .carousel-inner{
        min-height: 700px;
    }

    .carrouselContainer_mobil-position-1{
        left: 0;
    }
    .carrouselContainer_mobil-position-2{
        left: -900px;
    }
    .carrouselContainer_mobil-position-3{
        left: -1500px;
    }
    .carrouselContainer_mobil-position-4{
        left: -2300px;
    }
    .carrouselContainer_mobil-position-5{
        left: -3300px;
    }
}